.content-wrapper {
  display: flex;
  padding-top: 64px;
}

.main-content {
  flex-grow: 1;
  height: calc(100vh - 101px);
  overflow: auto;

  &.login,
  &.forgot-password {
    margin-top: 63px;
    position: relative;
  }

  &--container {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
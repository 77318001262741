.MuiOutlinedInput-root {
    border-radius: 1rem;

    .Mui-error .MuiOutlinedInput-notchedOutline {
        @include theme-aware('border-color', 'error');
    }

    &:hover .MuiOutlinedInput-notchedOutline,
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
        @include theme-aware('border-color', 'input-border');
    }
}

.MuiOutlinedInput-notchedOutline {
     @include theme-aware('border-color', 'input-border');
 }
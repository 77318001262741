.assessment-builder {
    &--elements {
        padding: 1rem;
        line-height: 2rem;
    }

    .content-item {
        border: 0.2rem solid Black;
        margin: 1.5rem 2rem 1rem 2rem;
        height: auto;
        padding: 1rem;
        align-items: center;

        &--title {
            font-size: 2rem;
        }

        & h3 {
            margin-top: 1rem;
            font-size: .7rem;
        }
    }

    .editing-content-item {
        width: 90%;
        height: 85%;
        background: #FFF;
        margin-left: 1.5rem;

        &--content {
            height: 83%;
        }

        &--headline {
            font-size: 1.5rem;
            margin: 1.5rem 0 1rem 0;
        }

        &--input {
            height: 5rem;
            width: 25rem;
        }

        &--space {
            display: flex;
            justify-content: space-between;
        }

        &--button {
            &-content {
                height: 2.5rem;
                width: 5.5rem;
                border: none;
                text-decoration: underline;
                cursor: pointer;
            }

            &-add {
                height: 2.5rem;
                width: 5.5rem;
                border: none;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .form-item {
        border: 0.2rem solid Black;
        margin-bottom: 2rem;
        text-align: center;
        height: 2.5rem;
        width: 17rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .tab-panel-content--container {
        width: 100%;
        height: 100%;
        min-height: 10rem;
    }

    .radio-utilities {
        &--container {
            width: 90%;
            height: 85%;
            background: #FFF;
            margin-left: 1.5rem;
        }

        &--content {
            height: 83%;
        }

        &--headline {
            font-size: 1.5rem;
            margin: 1.5rem 0 1rem 0;
        }

        &--input {
            display: flex;

            & h2 {
                font-size: 1.2rem;
            }
        }

        &--radio {
            margin-left: 1rem;
            margin-bottom: 1.5rem;
            width: 13rem;
            height: 2rem;
        }

        &--button {
            &-radio {
                margin-top: 2rem;
                height: 2rem;
                width: 14rem;
                text-align: left;
                border: none;
                text-decoration: underline;
                cursor: pointer;
            }

            &-content {
                height: 2.5rem;
                width: 5.5rem;
                border: none;
                text-decoration: underline;
                cursor: pointer;
            }

            &-add {
                height: 2.5rem;
                width: 5.5rem;
                border: none;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &--space {
            display: flex;
            justify-content: space-between;
        }
    }

    .add-tag-button {
        @include theme-aware('color', 'color');
        line-height: 0;
        border-radius: 9999px;

        padding: 2px;

        background: none;
        border: none;
    }
}
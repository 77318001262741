.login {
    &--form {
        margin-bottom: 20px;
    }

    &--submit {
        @include theme-aware('background-color', 'button-primary');
        @include theme-aware('border-color', 'button-primary');
    }

    &--forgot-password {
        margin-top: 10px;
        padding: 0;
    }

    .logo {
        margin-bottom: 25px;
    }
}
.header {
    @include theme-aware('background', 'header-background');
    color: white;
    text-align: center;
    flex-grow: 1;
    min-height: 64px;
    z-index: 1250 !important;

    &--logo {
        width: 216px;
    }

    .header--search {
        position: relative;
        width: 100%;
        margin-left: 50px;
        margin-right: 16px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.15);

        @media (min-width: $sm) {
            width: auto;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.25);
        }

        &-icon {
            padding: 0 16px;
            height: 100%;
            display: flex;
            position: absolute;
            align-items: center;
            pointer-events: none;
            justify-content: center;
        }

        &-input {
            &--root {
                color: inherit;
            }


            &--input {
                width: 100%;
                padding: 8px 8px 8px calc(1em + 32px);
                transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

                @media (min-width: $md) {
                    width: 20ch;
                }
            }
        }
    }

    &--desktop {
        display: none;

        @media (min-width: $md) {
            display: flex;
        }
    }

    &--mobile {
        display: flex;

        @media (min-width: $md) {
            display: none;
        }
    }

    .languageSwitch {
        @include theme-aware('color', 'color');
    }
}
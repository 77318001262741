.dropdown-menu {
    @include theme-aware('background-color', 'main');
}

.dropdown-item {
    @include theme-aware('color', 'color');

    &:hover {
        @include theme-aware('background-color', 'dropdown-hover');
    }

    &.active {
        background-color: transparent;
        @include theme-aware('color', 'selected');
    }
}

.btn {
    border-radius: 1rem;
}

.btn-link {
    @include theme-aware('color', 'color-secondary');
}
.select-project {
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    margin: 8px 16px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.25);
    }

    &-label {
        font-size: 1rem;
    }

    .select-project-input {
        &--root {
            width: 100%;
        }

        &--input {
            margin: 0;
            padding: 8px;
        }
    }

    &-list {
        max-height: 245px;
        overflow: auto;

        @media (min-width: $md) {
            max-height: 400px;
        }
    }

    &-menu {
        min-width: 200px;
    }
}
.footer {
  @include theme-aware('background', 'header-background');
  color: #ffffff;
  font-size: 12px;
  padding: 10px;
  text-align: center;

  a {
    color: #ffffff;
  }

  .logo img {
    margin-right: 20px;
    margin-bottom: 7px;
    width: 250px;
  }
}
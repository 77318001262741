body {
  @include theme-aware('background', 'color-background');
  @include theme-aware('color', 'color');
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.grow {
  flex-grow: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  @include theme-aware('-webkit-text-fill-color', 'color');
}

.align-self--center {
  align-self: center;
}

.text-align-center {
  text-align: center;
}

.vertical-align-middle {
  vertical-align: middle;
}

.theme-border {
  border: 1px solid;
  @include theme-aware('border-color', 'color-secondary');
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}
.MuiDataGrid {
    &-root {
        @include theme-aware('color', 'color');
        border: 0;

        .MuiDataGrid-cell {
            @include theme-aware('border-color', 'divider');
        }

        .MuiDataGrid-cell.MuiDataGrid-cellEditing {
            background: transparent;
        }

        .MuiDataGrid-cell--withRenderer > span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &-columnsContainer {
        @include theme-aware('border-color', 'divider');
    }

    &-columnSeparator {
        display: none;
    }

    &-columnHeaderTitleContainer {
        padding: 0;
    }

    &-overlay {
        background-color: transparent;
    }

    &-columnHeaderMoving {
        background: none;
    }
}
.MuiInputBase {
    &-root {
        @include theme-aware('color', 'color');
    }

    &-input {
        @include theme-aware('color', 'color');

        &:-webkit-autofill {
            transition: color 9999s ease-out, background-color 5000s ease-in-out 0s;
        }
    }
}
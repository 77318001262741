.main-modal {
    @include theme-aware('background-color', 'main');
    position: absolute;
    border: 1px solid #000;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 5px 8px 0 rgba(0,0,0,0.14), 0 1px 14px 0 rgba(0,0,0,0.12);
    padding: 16px 32px 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    overflow: auto;
    width: 100%;
}

.modal-size-sm {
    max-width: 300px;
}

.modal-size-md {
    max-width: 400px;
}

.modal-size-lg {
    max-width: 800px;
}

.modal-size-xl {
    max-width: 1140px;
}
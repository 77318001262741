.result-details {
    width: 80%;
    margin: 0 auto;

    &--col {
        padding: 16px;
    }

    &--value {
        @include theme-aware('color', 'color-secondary');
    }
}
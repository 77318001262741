.sidebar-wrapper {
  .sidebar-paper {
    position: relative;
    width: $drawer-width;
    @include theme-aware('background', 'main');
    @include theme-aware('color', 'color-secondary');
    padding: 32px 0;
  }

  .sidebar {
    width: 100%;

    &--item {
      &.active {
        @include theme-aware('color', 'color-highlight');

        .sidebar--item-icon {
          @include theme-aware('color', 'color-highlight');
        }
      }

      &-icon {
        @include theme-aware('color', 'color-secondary');
        min-width: auto;
        margin-right: 20px;
      }

      .MuiListItemText-inset {
        padding-left: 41px;
      }
    }
  }
}